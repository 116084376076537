.btn {
    &.btn-black {
        position: relative;
        background-color: $azul;
        border-radius: 0;
        color: #ffffff;
        text-transform: uppercase;
        font-weight: 400;
        font-size: .875rem;
        line-height: 2.25rem;
        padding-right: 0;
        svg {
            margin-left: 8px;
            transition: all .3s ease;
        }
        &:hover {
            svg {
                transform: translateX(4px);
            }
        }
    }
    &.btn-vermelho {
        position: relative;
        width: auto;
        padding: 5px 40px;
        margin: auto;
        background-color: $vermelhoClaro;
        border-radius: 0;
        color: $vermelho;
        text-transform: uppercase;
        font-weight: 400;
        font-size: .875rem;
        line-height: 2.25rem;
        border: $vermelho 1px solid;
        &:hover {
            background-color: $vermelho;
            color: #FFF;
            cursor: pointer;
        }
    }
}
