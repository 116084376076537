.servicos {
    position: relative;
    z-index: 1;
    display: block;
    width: 100%;
    height: auto;
    background-color: $azul;
    .boxH1 {
        margin-top: 200px;
        @include breakpoint(768px, max) {
            margin-top: 60px;
        }
        @include breakpoint(320px, max) {
            margin-top: 55px;
        }
    }
    h1 {
        left: -13px;
        position: absolute;
        top: 46px;
        color: $azulClaro;
        font-size: 1.125rem;
        line-height: 1.5rem;
        letter-spacing: 2px;
        text-transform: uppercase;
        text-transform: uppercase;
        -moz-transform: rotate(-90deg);
        -webkit-transform: rotate(-90deg);
        -o-transform: rotate(-90deg);
        -ms-transform: rotate(-90deg);
        transform: rotate(-90deg);
        opacity: 0;
        transition: all 1s ease;
        @include breakpoint(768px, max) {
            font-size: 1.125rem;
            line-height: 1.5rem;
        }
        @include breakpoint(850px, max) {
            left: -15px;
            font-size: 1rem;
            line-height: 1.5rem;
        }
        @include breakpoint(850px, max) {
            font-size: .875rem;
            line-height: .875rem;
        }
    }
    h2 {
        display: block;
        color: #ffffff;
        font-weight: bold;
        font-size: 30px;
        line-height: 38px;
        margin: 200px 0 50px;
        div {
            opacity: 0;
            transition: all 1s ease;
            &:first-child {
                transform: translateY(50px);
            }
            &:nth-child(2) {
                transform: translateY(60px);
            }
            &:nth-child(3) {
                transform: translateY(70px);
            }
            &:last-child {
                transform: translateY(80px);
            }
        }
        @include breakpoint(850px, max) {
            font-size: 1.125rem;
            line-height: 1.75rem;
            margin: 60px 0 25px;
        }
        @include breakpoint(768px, max) {
            font-size: 1.125rem;
            line-height: 1.75rem;
            margin: 60px 0 25px;
        }
        @include breakpoint(767px, max) {
            font-size: 1.0625rem;
            line-height: 1.75rem;
        }
        @include breakpoint(320px, max) {
            font-size: .875rem;
            line-height: 1.25rem;
        }
        span {
            font-weight: 400;
            color: $azulClaro;
        }
    }
    .texto {
        margin-bottom: 100px;
        @include breakpoint(768px, max) {
            margin-bottom: 60px;
        }
        ul {
            li {
                list-style-type: none;
                color: $azulClaro;
                font-size: 1.3125rem;
                line-height: 1.875rem;
                opacity: 0;
                transform: translateY(50px);
                transition: all 1s ease;
                @include breakpoint(768px, max) {
                    font-size: 1.125rem;
                    line-height: 1.75rem;
                }
                @include breakpoint(850px, max) {
                    font-size: 1rem;
                }
                @include breakpoint(320px, max) {
                    font-size: .875rem;
                }
            }
        }
    }
    .foto {
        position: absolute;
        width: 44%;
        height: 100%;
        top: 0;
        right: 0;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        opacity: 0;
        transform: translateX(60px);
        transition: all 1s ease;
        @include breakpoint(1600px, max) {
            width: 43%;
        }
        @include breakpoint(1440px, max) {
            width: 42.2%;
        }
        @include breakpoint(1366px, max) {
            width: 41.8%;
        }
        @include breakpoint(1280px, max) {
            width: 41.2%;
        }
        @include breakpoint(850px, max) {
            bottom: 0;
            width: 45%;
            height: 100%;
            background-position: 65% 100%;
        }
        @include breakpoint(768px, max) {
            bottom: 0;
            width: 45%;
            height: 100%;
        }
        @include breakpoint(767px, max) {
            display: none;
        }
    }
    .fotoMobile {
        display: none;
        position: relative;
        width: 100%;
        height: 400px;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        @include breakpoint(767px, max) {
            display: block;
        }
    }

    &.activeAnimations {
        h1 {
            opacity: 1;
        }
        h2 {
            div {
                opacity: 1;
                &:first-child {
                    transform: translateY(0px);
                }
                &:nth-child(2) {
                    transform: translateY(0px);
                }
                &:nth-child(3) {
                    transform: translateY(0px);
                }
                &:last-child {
                    transform: translateY(0px);
                }
            }
        }
        .texto {
            ul {
                li {
                    opacity: 1;
                    transform: translateY(0);
                }
            }
        }
        .foto {
            opacity: 1;
            transform: translateX(0);
        }
    }
}