.bodyVermelho {
    header {
        &::before {
            background-color: $vermelho;
        }
        .logo {
            .vermelho {
                display: block;
            }
        }
        .menu {
            .botaoResponsivo {
                .linhasmr {
                    >span {
                        background-color: #FFF;
                    }
                    &.ativo {
                        >span {
                            background-color: $vermelho;
                        }
                    }
                }
            }
            nav {
                ul {
                    @include breakpoint(768px, max) {
                        border-color: $vermelho;
                    }
                    li {
                        @include breakpoint(768px, max) {
                            border-color: $vermelho;
                        }
                        a {
                            color: #D4837D;
                            @include breakpoint(768px, max) {
                                color: $vermelho;
                            }
                            &:before {
                                background-color: #D4837D;
                            }
                            &:hover {
                                color: #ffffff;
                                @include breakpoint(768px, max) {
                                    color: $vermelho;
                                }
                            }
                        }
                        &.active {
                            a {
                                color: #FFF;
                                @include breakpoint(768px, max) {
                                    color: $vermelho;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    footer {
        background-color: $vermelho;
        .imgBlue {
            display: none;
        }
        .imgRed {
            display: block;
        }
        .infos {
            a {
                color: $vermelhoClaro;
            }
            address {
                color: $vermelhoClaro;
            }
            p {
                color: $vermelhoClaro;
            }
        }
        .redes {
            nav {
                ul {
                    li {
                        &:first-child {
                            border-color: $vermelhoClaro;
                        }
                        a {
                            color: $vermelhoClaro;
                            &:hover {
                                color: #000000;
                            }
                        }
                    }
                }
            }
        }
        .copyright {
            color: #B84645;
            a {
                color: #B84645;
            }
        }
        .btnTopoBlue {
            display: none;
        }
        .btnTopoRed {
            display: block;
        }
    }
}