.notFound {
    padding: 100px 0;
    h1 {
        font-weight: bold;
        font-size: 3rem;
        text-align: center;
    }
    h2 {
        font-size: 2.25rem;
        text-align: center;
    }
    a {
        display: table;
        text-align: center;
        width: auto;
        margin: 10px auto 0;
        border-radius: 6px;
        background-color: #000;
        color: #ffffff;
        text-transform: uppercase;
        font-size: .875rem;
        &:hover {
            background-color: $azul;
            color: #ffffff;
        }
    }
}