@font-face {
    font-family: 'Gotham';
    src: url($fontsSRC + 'Gotham-Book.eot');
    src: url($fontsSRC + 'Gotham-Book.eot?#iefix') format('embedded-opentype'),
        url($fontsSRC + 'Gotham-Book.svg#Gotham-Book') format('svg'),
        url($fontsSRC + 'Gotham-Book.ttf') format('truetype'),
        url($fontsSRC + 'Gotham-Book.woff') format('woff'),
        url($fontsSRC + 'Gotham-Book.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham';
    src: url($fontsSRC + 'Gotham-Bold.eot');
    src: url($fontsSRC + 'Gotham-Bold.eot?#iefix') format('embedded-opentype'),
        url($fontsSRC + 'Gotham-Bold.svg#Gotham-Bold') format('svg'),
        url($fontsSRC + 'Gotham-Bold.ttf') format('truetype'),
        url($fontsSRC + 'Gotham-Bold.woff') format('woff'),
        url($fontsSRC + 'Gotham-Bold.woff2') format('woff2');
    font-weight: bold;
    font-style: normal;
}