/*
  SASS Variables for project
 */
$version: "v1.0.0";
$author: "Alexander Moraes";

// COLORS DEFAULT
$valid: #2ecc71;
$error: #FF0000;

//CORES LAYOUT
$azul: #013571;
$azulClaro: #4F92B3;
$vermelho: #92302F;
$vermelhoClaro: #D4837D;
$preto: #000000;
$cinza: #C7C7C7;

//Tamanho Container
$containerWidth: 1170px;


//Font Padrão do Site
$fontDefault: 'Gotham', sans-serif;;
$fontTitulo: 'Gotham', sans-serif;;

$corBarraResponsivo: #073954;

// PATHS
$fontsSRC: "assets/fonts/";
$img: "assets/imgs/";


// BREAKPOINTS
$breakpoints: (max: (xs: 767px), min: (sm: 768px, md: 992px, lg: 1200px));