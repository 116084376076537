.empresa {
    position: relative;
    z-index: 2;
    display: block;
    width: 100%;
    height: auto;
    background-color: $azulClaro;
    padding: 40px 0 0 ;
    @include breakpoint(768px, max) {
        padding: 60px 0 0px;
    }

    h1 {
        position: absolute;
        bottom: 130px;
        left: -100px;
        z-index: 3;
        color: #ffffff;
        font-weight: bold;
        font-size: 3.75rem;
        line-height: 3.25rem;
        text-transform: uppercase;
        opacity: 0;
        transform: translateX(-50px);
        transition: all 1s ease;
        @include breakpoint(850px, max) {
            left: -60px;
        }
        @include breakpoint(768px, max) {
            bottom: 40px;
            font-size: 3rem;
            line-height: 2.625rem;
        }
        .linha2 {
            padding-left: 40px;
            opacity: 0;
            transform: translateX(-50px);
            transition: all 1s ease;
        }
    }

    .foto {
        position: relative;
        z-index: 1;
        transform: translateY(80px);
        opacity: 0;
        transition: all 1s ease;
        @include breakpoint(768px, max) {
            transform: translateY(0);
        }
        figure {
            img {
                box-shadow: 10px 17.32px 45px rgba(0, 1, 0, 0.25);
            }
        }
    }

    .texto {
        display: block;
        width: 100%;
        margin-bottom: 80px;
        margin-top: 30px;
        opacity: 0;
        transform: translateX(50px);
        transition: all 1s ease;
        p {
            font-size: 1rem;
            line-height: 1.5rem;
            color: #ffffff;
            margin-bottom: 20px;
            @include breakpoint(768px, max) {
                font-size: .875rem;
                line-height: 1.125rem;
                margin-bottom: 15px;
            }
            strong {
                font-weight: bold;
            }
        }
    }

    &.activeAnimations {
        h1 {
            opacity: 1;
            transform: translateX(0);
            .linha2 {
                opacity: 1;
                transform: translateX(0);
            }
        }
        .foto {
            opacity: 1;
        }
        .texto {
            opacity: 1;
            transform: translateX(0);
        }
    }

}