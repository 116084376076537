/*!
Theme Name: Capacita 2020
Theme URI: https://www.agencidominio.com.br/
Description: Site desenvolvido especificamente para uso da Viu Multimidia;
Author: Agencia Dominio
Author URI: https://www.agencidominio.com.br/
Version: 2.0
*/

// Imports
@import "mixins/mixins";
@import "commom/imports";
@import "commom/variables";
@import "commom/fonts";
@import "commom/default";
@import "commom/padroes";
@import "commom/loader";
@import "commom/compartilhe";

//Components
@import "components/buttons";
@import "components/inputs";

//Layout Site
@import "header";

@import "vitrine";
@import "empresa";
@import "servicos";
@import "cases";
@import "contato";
@import "notFound";
@import "footer";

@import "bodyAzul";
@import "bodyVermelho";
@import "bodyPreto";
//Paginas
