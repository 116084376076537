.compartilhe {
    margin-top: 0px;
    .container {
        max-width: 1000px;
        @include breakpoint(850px, max) {
            padding: 0;
        }
    }
    .btnBack {
        display: inline-block;
        vertical-align: middle;
        width: 100%;
        max-width: 50px;
    }
    .shares {
        position: relative;
        display: inline-block;
        vertical-align: middle;
        width: 100%;
        max-width: calc( 100% - 55px );
        text-align: right;
        &::before {
            position: absolute;
            z-index: -1;
            content: '';
            width: 100%;
            height: 1px;
            background-color: $vermelho;
            left: 0;
            top: 32%;
        }
    }
    ul {
        display: inline-block;
        width: auto;
        background-color: #fff;
        li {
            display: inline-block;
            list-style-type: none;
            margin-left: 10px;
            @include breakpoint(850px, max) {
                margin-left: 7px;
            }
            a {
                font-size: 20px;
                border-radius: 5px;
                color: #C8C8C8;
                border: #C8C8C8 1px solid;
                border-radius: 50%;
                width: 30px;
                height: 30px;
                display: inline-block;
                text-align: center;
                padding: 6px;
                font-size: 14px;
                background-color: #fff;
                transition: all .3s ease;
                &.linkBack {
                    border-color: $vermelho;
                    background-color: $vermelho;
                    color: #ffffff;
                    padding: 7px 6px;
                }
                &.linkShare {
                    border-color: $vermelho;
                    background-color: $vermelho;
                    color: #ffffff;
                    text-align: left;
                    padding-left: 7px;
                }
                &.linkFacebook {
                    
                }
                &.linkTwitter {
                    
                }
                &.linkPinterest {
                    
                }
                &.linkGooglePlus {
                    
                }
                &.linkWhatsApp {
                    font-size: 16px;
                }
                &.linkEmail {
                    
                }
                .fa-inverse {
                    font-size: 18px;
                }
                &:hover {
                    background-color: $vermelho;
                    border-color: $vermelho;
                    color: #ffffff;
                    &.linkBack {
                        transform: scale(1.3);
                    }
                }
            }
        }
    }
}