.bodyAzul {
    header {
        &::before {
            background-color: $azul;
        }
        .logo {
            .azul {
                display: block;
            }
        }
        .menu {
            .botaoResponsivo {
                .linhasmr {
                    >span {
                        background-color: #FFF;
                    }
                    &.ativo {
                        >span {
                            background-color: $azul;
                        }
                    }
                }
            }
            nav {
                ul {
                    li {
                        a {
                            color: $azulClaro;
                            @include breakpoint(767px, max) {
                                color: $azul;
                            }
                            &:before {
                                background-color: $azulClaro;
                            }
                            &:hover {
                                color: #ffffff;
                                @include breakpoint(767px, max) {
                                    color: $azul;
                                }
                            }
                        }
                        &.active {
                            a {
                                color: #FFF;
                                @include breakpoint(767px, max) {
                                    color: $azul;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    footer {
        background-color: $azulClaro;
        .imgBlue {
            display: block;
        }
        .imgRed {
            display: none;
        }
        .infos {
            a {
                color: $azul;
            }
            address {
                color: $azul;
            }
            p {
                color: $azul;
            }
        }
        .redes {
            nav {
                ul {
                    li {
                        &:first-child {
                            border-color: $azul;
                        }
                        a {
                            color: $azul;
                            &:hover {
                                color: #000000;
                            }
                        }
                    }
                }
            }
        }
        .copyright {
            color: #2b7397;
            a {
                color: #2b7397;
            }
        }
        .btnTopoBlue {
            display: block;
        }
        .btnTopoRed {
            display: none;
        }
    }
}