.bodyPreto {
    header {
        &::before {
            background-color: $preto;
        }
        .logo {
            .preto {
                display: block;
            }
        }
        .menu {
            .botaoResponsivo {
                .linhasmr {
                    &.ativo {
                        >span {
                            background-color: $preto;
                        }
                    }
                }
            }
            nav {
                ul {
                    @include breakpoint(850px, max) {
                        border-color: $preto;
                    }
                    li {
                        @include breakpoint(850px, max) {
                            border-color: $preto;
                        }
                        a {
                            color: #808080;
                            @include breakpoint(850px, max) {
                                color: $preto;
                            }
                            &:before {
                                background-color: #808080;
                            }
                            &:hover {
                                color: #ffffff;
                            }
                        }
                        &.active {
                            a {
                                color: #FFF;
                                @include breakpoint(850px, max) {
                                    color: $preto;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}