.vitrine {
    position: relative;
	display: block;
	width: 100vw;
    height: auto;
	z-index: 2;
    transition: all 2s ease;
    padding-bottom: 80px;
    @include breakpoint(850px, max) {
        background-color: $azul;
    }
    
    &::before {
        content: '';
        position: absolute;
        z-index: 1;
        height: 100%;
        width: 60%;
        top: 0;
        right: 0;
        background-color: $azul;
        @include breakpoint(850px, max) {
            display: none;
        }
    }

    .container {
        max-width: 100%;
        padding: 0;
    }

	.carousel {
        position: relative;
        z-index: 2;
        @include breakpoint(850px, max) {
            margin-bottom: 40px;
        }
        .owl-item {
            .item {
                display: block;
                width: 100%;
                min-height: 560px;
                align-items: flex-end;
                background-repeat: no-repeat;
                @include breakpoint(768px, max) {
                    min-height: 360px;
                }
                @include breakpoint(850px, max) {
                    min-height: 250px;
                    display: block;
                }
                .container {
                    width: 100%;
                    max-width: 1366px;
                    position: relative;
                }
                .foto {
                    position: relative;
                    z-index: 1;
                    width: 100%;
                    height: auto;
                    min-height: 560px;
                    background-repeat: no-repeat;
                    background-position: center center;
                    background-size: cover;
                    transition: all 1s ease;
                    &::before {
                        position: absolute;
                        z-index: 1;
                        content: '';
                        height: 100%;
                        width: 90%;
                        left: 50%;
                        transform: translateX(-50%);
                        box-shadow: 10px 17.321px 45px 0px rgba(0, 1, 0, 0.25);  
                        border-radius: 10%;
                    }
                    @include breakpoint(768px, max) {
                        min-height: 360px;
                    }
                    @include breakpoint(850px, max) {
                        position: relative;
                        min-height: 250px;
                        margin-bottom: 20px;
                    }
                }
                h2 {
                    position: relative;
                    z-index: 2;
                    width: 100%;
                    max-width: 360px;
                    font-size: 1rem;
                    line-height: 1.25rem;
                    color: $azul;
                    text-transform: uppercase;
                    margin-bottom: 0;
                    margin-top: 15px;
                    margin-left: 10%;
                    opacity: 1;
                    transition: all 1s ease;
                    @include breakpoint(850px, max) {
                        max-width: 65%;
                        // padding: 0 15px;
                        // font-size: .875rem;
                        // line-height: 1rem;
                        // margin-left: 2%;
                        color: #ffffff;
                    }
                    @include breakpoint(768px, max) {
                        max-width: 65%;
                        padding: 0 15px;
                        font-size: .875rem;
                        line-height: 1rem;
                        margin-left: 2%;
                    }
                    @include breakpoint(680px, max) {
                        color: #ffffff;
                        margin: 0 0 20px 0;
                        max-width: 100vw;
                        padding: 0 30px;
                    }
                    // @include breakpoint(850px, max) {
                    //     padding: 0 0 15px;
                    //     margin-top: 20px;
                    //     margin-bottom: 15px;
                    // }
                }
                
            }
        }
        .owl-nav {
            position: absolute;
            width: 80px;
            bottom: 40px;
            right: 0;
            z-index: 3;
            &::before {
                background-color: $azulClaro;
                position: absolute;
                content: "";
                width: 2px;
                height: 20px;
                z-index: 1;
                left: 50%;
                transform: translateX(-50%);
                @include breakpoint(850px, max) {
                    left: 40px;
                }
            }
            @include breakpoint(768px, max) {
                bottom: 28px;
            }
            @include breakpoint(680px, max) {
                right: auto;
                left: 50%;
                transform: translateX(-50%);
                width: 100%;
                max-width: calc(100vw - 50px);
                bottom: 0;
                padding: 0 30px;
            }
            button {
                position: absolute;
                z-index: 2;
                display: table;
                width: 20px;
                height: 20px;
                background-repeat: no-repeat;
                background-position: center center;
                span {
                    display: none;
                }
                &.owl-prev {
                    background-image: url($img + 'vitrine/left.png');
                    left: 0;
                    @include breakpoint(850px, max) {
                        left: 0;
                    }
                }
                &.owl-next {
                    background-image: url($img + 'vitrine/right.png');
                    right: 0;
                    @include breakpoint(768px, max) {
                        left: 60px;
                    }
                }
                &:hover, &:active, &:focus {
                }
            }
        }
        .owl-dots {
            position: absolute;
            width: auto;
			left: 50%;
			margin: 0;
			padding: 0;
			display: block;
            text-align: center;
            z-index: 10;
            bottom: 80px;
            height: 20px;
            @include breakpoint(850px, max) {
                transform: translateX(-50%);
            }
            button {
                &.owl-dot {
					border-radius: 50%;
					background-color: $azulClaro;
                    width: 8px;
                    height: 8px;
                    margin: 0 5px;
                    // border: $natVerdeClaro 3px solid;
                    outline: none;
                    &.active {
                        background-color: #FFF;
                    }
                }
            }
        }
    }

    .conteudo {
        position: relative;
        z-index: 3;
        h1 {
            display: block;
            padding-left: 95px;
            color: #ffffff;
            font-weight: bold;
            font-size: 3rem;
            line-height: 3.625rem;
            margin-bottom: 20px;
            opacity: 1;
            transition: all 1s ease;
            @include breakpoint(850px, max) {
                padding-left: 0;
                font-size: 2.5rem;
                line-height: 2.8rem;
            }
            @include breakpoint(768px, max) {
                padding-left: 15px;
                font-size: 1.875rem;
                line-height: 2.25rem;
            }
            @include breakpoint(680px, max) {
                padding: 0 20px;
                font-size: 2.5rem;
                line-height: 3rem;
                border-top: $azulClaro 1px solid;
                padding-top: 30px;
            }
            @include breakpoint(320px, max) {
                padding: 0;
                font-size: 2.5rem;
                line-height: 3rem;
            }
            span {
                font-weight: 400;
                color: $azulClaro;
            }
        }
        p {
            display: block;
            width: 100%;
            max-width: 420px;
            padding-left: 95px;
            color: $azulClaro;
            font-size: 1.125rem;
            line-height: 1.375rem;
            margin-bottom: 110px;
            transition: all 1s ease;
            @include breakpoint(768px, max) {
                padding-left: 15px;
                font-size: .875rem;
                line-height: 1rem;
            }
            @include breakpoint(850px, max) {
                padding: 0 20px;
                font-size: .9375rem;
                line-height: 1.125rem;
                margin-bottom: 0;
            }
            @include breakpoint(320px, max) {
                padding: 0;
            }
        }
    }
}