* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

body {
    font: lineHeight(14px, 120%) $fontDefault;
    overflow-x: hidden !important;
    padding: 0;
    margin: 0;
    width: 100vw;
    height: 100%;
    font-weight: 500;
    background-color: #FFF;
}