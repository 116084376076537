.contato {
    position: relative;
    z-index: 1;
    display: block;
    width: 100%;
    height: auto;
    padding: 0 0 100px;
    @include breakpoint(767px, max) {
        background-color: $azul;
    }
    &::before {
        content: '';
        position: absolute;
        z-index: 1;
        height: 100%;
        width: 60%;
        top: 0;
        right: 0;
        background-color: $azul;
        @include breakpoint(767px, max) {
            display: none;
        }
    }
    .container {
        position: relative;
        z-index: 2;

        .mapa {
            opacity: 0;
            transform: translateX(-60px);
            transition: all 1s ease;
            iframe {
                position: relative;
                z-index: 2;
                margin-bottom: 15px;
                box-shadow: 10px 17.32px 45px rgba(0, 1, 0, 0.15);
                @include breakpoint(850px, max) {
                    max-height: 430px;
                }
                @include breakpoint(767px, max) {
                    margin-top: -1px;
                    margin-bottom: -5px;
                    max-height: 300px;
                }
            }
            a {
                font-size: 1rem;
                line-height: 1.25rem;
                color: $azul;
                @include breakpoint(767px, max) {
                    display: block;
                    width: 100%;
                    padding: 30px;
                    background-color: #F3F3F3;
                }
                svg {
                    margin-left: 8px;
                    transition: all .3s ease;
                }
                &:hover {
                    text-decoration: none;
                    color: $azul;
                    svg {
                        margin-left: 12px;
                    }
                }
            }
        }

        h2 {
            font-weight: bold;
            font-size: 1.875rem;
            line-height: 2.25rem;
            margin-bottom: 10px;
            color: #ffffff;
            margin-top: 100px;
            opacity: 0;
            transform: translateY(60px);
            transition: all 1s ease;
            @include breakpoint(850px, max) {
                padding: 0 20px;
                margin-top: 0;
            }
            @include breakpoint(767px, max) {
                padding: 0 20px;
            }
        }

        p {
            font-size: .875rem;
            line-height: 1.5rem;
            color: #5092B3;
            opacity: 0;
            transition: all 1s ease;
            @include breakpoint(850px, max) {
                padding: 0 20px;
            }
        }

        .linha {
            display: block;
            width: 0;
            max-width: 65px;
            height: 2px;
            background-color: #ffffff;
            margin-bottom: 30px;
            opacity: 0;
            transition: all 1s ease;
            @include breakpoint(850px, max) {
                margin-left: 20px;
            }
        }

        .formulario {
            opacity: 0;
            transition: all 1s ease;
            @include breakpoint(850px, max) {
                padding: 0 20px;
            }
            form {
                .form-group {
                    .form-control {

                    }
                }
            }
        }
    }

    &.activeAnimations {
        .container {
            .mapa {
                opacity: 1;
                transform: translateX(0);
            }
            h2 {
                opacity: 1;
                transform: translateY(0);
            }
            p {
                opacity: 1;
            }
            .linha {
                opacity: 1;
                width: 100%;
            }
            .formulario {
                opacity: 1;
            }
        }
    }
}