footer {
    display: block;
    width: 100%;
    height: auto;
    background-color: $cinza;
    padding: 100px 0;
    @include breakpoint(768px, max) {
        padding: 60px 0;
    }

    .imgBlue {
        display: block;
    }
    .imgRed {
        display: none;
    }

    .infos {
        @include breakpoint(480px, max) {
            margin-bottom: 40px;
        }
        a {
            display: table;
            font-weight: bold;
            font-size: 1.125rem;
            line-height: 1.3125rem;
            color: #000000;
            @include breakpoint(480px, max) {
                font-size: 1rem;
                line-height: 1.5rem;
            }
            &:hover {
                text-decoration: none;
                color: $azul;
            }
        }
        address {
            display: block;
            font-size: .875rem;
            line-height: 1.5rem;
            margin-bottom: 20px;
            color: #000000;
        }
        p {
            font-size: .875rem;
            line-height: 1.5rem;
            color: #000000;
            margin-bottom: 0;
        }
    }

    .redes {
        margin-bottom: 78px;
        @include breakpoint(850px, max) {
            margin-bottom: 60px;
            margin-top: 50px;
            padding-left: 70px;
        }
        @include breakpoint(768px, max) {
            margin-bottom: 102px;
            margin-top: 0;
            padding-left: 0px;
        }
        @include breakpoint(680px, max) {
            margin-top: 50px;
        }
        @include breakpoint(480px, max) {
            margin-bottom: 80px;
            padding-left: 0;
        }
        nav {
            ul {
                li {
                    position: relative;
                    display: inline-block;
                    list-style-type: none;
                    padding: 0 20px;
                    &:first-child {
                        border-right: #000 1px solid;
                        padding-left: 0;
                    }
                    a {
                        color: #000000;
                        font-size: 1.125rem;
                        line-height: 1.25rem;
                        text-decoration: none;
                        &:hover {
                            text-decoration: none;
                            color: $azul;
                        }
                    }
                }
            }
        }
    }

    .copyright {
        font-size: .875rem;
        line-height: 1.5rem;
        color: #9d9d9c;
        @include breakpoint(850px, max) {
            padding-left: 70px;
        }
        @include breakpoint(768px, max) {
            padding-left: 0px;
        }
        @include breakpoint(680px, max) {
            padding-left: 0px;
        }
        p {
            margin-bottom: 0;
        }
        a {
            color: #9d9d9c;
            &:hover {
                text-decoration: none;
                color: $azul;
            }
        }

    }
}