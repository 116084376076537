header {
    position: relative;
    display: block;
    width: 100%;
    height: auto;
    padding: 55px 0;
    @include breakpoint(480px, max) {
        padding: 40px 0;
    }
    &::before {
        content: '';
        position: absolute;
        z-index: 1;
        height: 100%;
        width: 60%;
        top: 0;
        right: 0;
        @include breakpoint(480px, max) {
            width: 30%;
        }
    }
    .container {
        @include breakpoint(850px, max) {
            padding: 0 30px;
        }
    }
    .logo {
        position: relative;
        z-index: 9999;
        a {
            img {
                display: none;
            }
        }
    }
    .menu {
        position: relative;
        z-index: 999;
        right: 0;
        text-align: right;
        transition: all .3s ease;
        @include breakpoint(768px, max) {
            padding-top: 0;
        }
        .botaoResponsivo {
            width: 100px;
            height: 50px;
            position: absolute;
            right: 0;
            display: none;
            margin-top: 0;
            top: 0;
            z-index: 9999;
            @include breakpoint(768px, max) {
                display: inline-block;
            }
            @include breakpoint('320px', 'max') {
                width: 75px;
            }
            .linhasmr {
                float: right;
                margin-top: 8px;
                .b1 {
                    top: 11px;
                }
                .b2 {
                    top: 18px;
                }
                .b3 {
                    top: 25px;
                }
                >span {
                    background-color: #808080;
                    height: 2px;
                    right: 0;
                    position: absolute;
                    top: 0;
                    transition: all 0.3s ease 0s;
                    width: 30px;
                    border-radius: 2px;
                }
                &.ativo {
                    >span {
                        background-color: #808080;
                    }
                    .b1 {
                        transform: rotate(-45deg);
                        -webkit-transform: rotate(-45deg);
                        top: 19px;
                        right: 0;
                    }
                    .b2 {
                        width: 0px;
                    }
                    .b3 {
                        transform: rotate(45deg);
                        -webkit-transform: rotate(45deg);
                        top: 19px;
                        right: 0;
                    }
                }
            }
        }
        nav {
            display: inline-block;
            transition: all .4s ease-in-out;
            @include breakpoint(768px, max) {
                width: 100vw;
                display: block;
                position: absolute;
                z-index: 3;
                background-color: #FFF;
                right: 0;
                position: fixed;
                height: auto;
                overflow: auto;
                top: 0px;
                height: 0;
            }
            ul {
                margin: 0;
                @include breakpoint(768px, max) {
                    padding: 0;
                    margin: 0;
                    display: block;
                    overflow: auto;
                    margin-top: 115px;
                    border-top: $azul 1px solid;
                }
                li {
                    list-style-type: none;
                    display: inline-block;
                    @include breakpoint(768px, max) {
                        display: block;
                        border-bottom: $azul 1px solid;
                    }
                    a {
                        position: relative;
                        display: block;
                        width: auto;
                        color: $azulClaro;
                        font-size: 1rem;
                        line-height: 1.25rem;
                        transition: all .3s ease;
                        margin-left: 85px;
                        @include breakpoint(768px, max) {
                            margin-left: auto;
                            text-align: center;
                            font-size: 1.5rem;
                            line-height: 1.75rem;
                            padding: 15px 0;
                        }
                        &:before {
                            display: block;
                            content: '';
                            position: absolute;
                            background-color: $azulClaro;
                            z-index: 1;
                            height: 2px;
                            width: 0;
                            left: 50%;
                            transform: translateX(-50%);
                            transition: all .3s ease-in-out;
                            bottom: -10px;
                            @include breakpoint(768px, max) {
                                display: none;
                            }
                        }
                        &:hover {
                            text-decoration: none;
                            font-weight: bold;
                            color: #ffffff;
                            &:before {
                                width: 30px;
                            }
                        }
                    }
                    &.active {
                        a {
                            font-weight: bold;
                            color: #FFF;
                            &:before {
                                width: 30px;
                            }
                        }
                    }
                }
            }
            &.ativo {
                // right: 0px;
                // top: 80px;
                height: 100%;
            }
        }
    }
}