.form-group {
    margin-bottom: 8px;
}

.form-control {
    font-family: $fontDefault;
    border-radius: 0;
    background-color: transparent;
    border: 0;
    border-bottom: #818181 0.5px solid;
    box-shadow: none;
    
    font-size: .875rem;
    line-height: 1.5rem;
    color: #FFF;
    font-weight: 400;
    padding: 8px 0;
    height: auto;
    outline: none;
    &::-webkit-input-placeholder { /* Edge */
        color: #5092B3;
    }
      
    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: #5092B3;
    }
      
    &::placeholder {
        color: #5092B3;
    }
    &:focus {
        background-color: transparent;
        color: #FFF;
        border-color: #fff;
        outline: 0px none !important;
    }
}