.cases {
    position: relative;
    z-index: 1;
    display: block;
    width: 100%;
    height: auto;
    background-color: $vermelhoClaro;
    padding: 100px 0;
    a {
        display: table;
        width: 100%;
        max-width: 350px;
        margin-bottom: 30px;
        @include breakpoint(860px, max) {
            margin: auto auto 30px;
        }
        figure {
            border-top: $vermelho 5px solid;
            display: block;
            overflow: hidden;
            max-height: 215px;
            img {
                transition: all .3s ease;
            }
        }
        h2 {
            display: block;
            font-size: 1rem;
            line-height: 20px;
            padding-bottom: 20px;
            border-bottom: $vermelho 2px solid;
            color: $preto;
            min-height: 62px;
        }
        &:hover {
            text-decoration: none;
            figure {
                img {
                    transform: scale(1.1);
                }
            }
            h2 {
                text-decoration: none;
                color: $preto;
            }
        }
    }
    .botaoMais {
        text-align: center;
    }

    &.single {
        background-color: #FFF;
        padding: 0 0 100px;
        @include breakpoint(850px, max) {
            padding-bottom: 50px;
        }
        .container {
            position: relative;
            z-index: 1;
            @include breakpoint(850px, max) {
                padding: 0 30px;
            }
        } 
        .destaque {
            position: relative;
            display: block;
            width: 100%;
            height: auto;
            background-color: $vermelhoClaro;
            min-height: 600px;
            @include breakpoint(850px, max) {
                min-height: 500px;
            }
            @include breakpoint(768px, max) {
                min-height: 360px;
            }
            @include breakpoint(767px, max) {
                min-height: 0;
            }
            .container {
                position: relative;
                z-index: 1;
                .coluna {
                    min-height: 600px;
                    @include breakpoint(850px, max) {
                        min-height: 500px;
                    }
                    @include breakpoint(768px, max) {
                        min-height: 360px;
                    }
                    @include breakpoint(767px, max) {
                        min-height: 0;
                    }
                }
            }
            a {
                display: inline-block;
                font-size: .875rem;
                line-height: 36px;
                letter-spacing: 2px;
                color: $vermelho;
                text-transform: uppercase;
                margin-top: 50px;
                
                @include breakpoint(768px, max) {
                    margin-top: 30px;
                }
                @include breakpoint(767px, max) {
                    margin-bottom: 30px;
                    display: block;
                }
                svg {
                    transition: all .3s ease;
                }
                &:hover {
                    text-decoration: none;
                    color: $vermelho;
                    svg {
                        transform: translateX(-4px);
                    }
                }
            }
            .infos {
                h1 {
                    position: absolute;
                    margin-bottom: 15px;
                    font-size: 1.875rem;
                    line-height: 2.25rem;
                    font-weight: bold;
                    color: $vermelho;
                    top: 42%;
                    transform: translateY(-50%);
                    @include breakpoint(768px, max) {
                        font-size: 1.5rem;
                        line-height: 1.75rem;
                        top: auto;
                        bottom: 30px;
                        transform: translateY(0);
                    }
                    @include breakpoint(767px, max) {
                        position: relative;
                        top: auto;
                        bottom: auto;
                        display: block;
                        margin-bottom: 60px;
                    }
                    &::before {
                        content: '';
                        position: absolute;
                        bottom: -15px;
                        left: 0;
                        width: 65px;
                        height: 2px;
                        background-color: $vermelho;
                    }
                }
                .dados {
                    position: absolute;
                    bottom: 60px;
                    font-size: .875rem;
                    line-height: 24px;
                    color: $vermelho;
                    @include breakpoint(768px, max) {
                        display: none;
                    }
                    strong {
                        font-weight: bold;
                    }
                }
            }

            .carouselCase {
                position: absolute;
                width: 60%;
                height: 600px;
                right: 0;
                z-index: 2;
                top: 0;
                @include breakpoint(850px, max) {
                    height: 500px;
                }
                @include breakpoint(768px, max) {
                    height: 360px;
                }
                @include breakpoint(767px, max) {
                    position: relative;
                    width: 100%;
                    height: 310px;
                }
                .owl-item {
                    min-height: 600px;
                    @include breakpoint(850px, max) {
                        min-height: 500px;
                    }
                    @include breakpoint(768px, max) {
                        min-height: 360px;
                    }
                    @include breakpoint(767px, max) {
                        min-height: 310px;
                    }
                    .item {
                        position: absolute;
                        width: 100%;
                        height: 600px; 
                        background-repeat: no-repeat;
                        background-position: center center;  
                        background-size: cover;
                        @include breakpoint(850px, max) {
                            height: 500px;
                        }
                        @include breakpoint(768px, max) {
                            height: 360px;
                        }
                        @include breakpoint(767px, max) {
                            min-height: 310px;
                        }
                    }
                }
                .owl-nav {
                    position: absolute;
                    width: 100%;
                    top: 50%;
                    left: 0;
                    z-index: 3;
                    right: 0;
                    transform: translateY(-50%);
                    button {
                        position: absolute;
                        display: table;
                        width: 20px;
                        height: 20px;
                        background-repeat: no-repeat;
                        background-position: center center;
                        span {
                            display: none;
                        }
                        &.owl-prev {
                            background-image: url($img + 'cases/left.png');
                            left: 30px;
                            @include breakpoint(768px, max) {
                                // left: 65vw;
                            }
                        }
                        &.owl-next {
                            background-image: url($img + 'cases/right.png');
                            right: 30px;
                        }
                        &:hover, &:active, &:focus {
                        }
                    }
                }
                .owl-dots {
                    position: absolute;
                    width: auto;
                    left: 50%;
                    transform: translateX(-50%);
                    margin: 0;
                    padding: 0;
                    display: block;
                    text-align: center;
                    z-index: 10;
                    bottom: 35px;
                    height: 20px;
                    button {
                        &.owl-dot {
                            border-radius: 50%;
                            background-color: $vermelho;
                            width: 8px;
                            height: 8px;
                            margin: 0 5px;
                            // border: $natVerdeClaro 3px solid;
                            outline: none;
                            &.active {
                                background-color: #FFF;
                            }
                        }
                    }
                }
            }

        }
        .infosText {
            display: none;
            @include breakpoint(768px, max) {
                display: block;
                margin-top: 80px;
            }
            .dados {
                font-size: .875rem;
                line-height: 24px;
                color: $preto;
                @include breakpoint(767px, max) {
                    font-size: .8125rem;
                    line-height: 1.3125rem;
                }
                strong {
                    font-weight: bold;
                }
            }
        }
        .texto {
            margin: 60px 0 60px;
            p {
                font-size: 1rem;
                line-height: 1.5rem;
                color: $preto;
            }
        }
        .backButtons {
            a {
                display: inline-block;
                font-size: .875rem;
                line-height: 36px;
                letter-spacing: 2px;
                color: $preto;
                text-transform: uppercase;
                svg {
                    transition: all .3s ease;
                }
                &:hover {
                    text-decoration: none;
                    color: $preto;
                    &.leftButton {
                        svg {
                            transform: translateX(-4px);
                        }
                    }
                    &.rightButton {
                        svg {
                            transform: translateX(4px);
                        }
                    }
                }
            }
        }
    }
}